import { default as historyIR4l0mlf3lMeta } from "/app/pages/account/history.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as securitySrTMI42t3JMeta } from "/app/pages/account/security.vue?macro=true";
import { default as teamV6NuqkxgJWMeta } from "/app/pages/account/team.vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as showJgD1hI1UyBMeta } from "/app/pages/articles/show.vue?macro=true";
import { default as email_45not_45verified_45redirectwTbqgD1Vv3Meta } from "/app/pages/auth/investor/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verifiedMmSahp8JOlMeta } from "/app/pages/auth/investor/email-not-verified.vue?macro=true";
import { default as create_45passwordqVQ3ZonFVQMeta } from "/app/pages/auth/investor/onboarding/create-password.vue?macro=true";
import { default as registerhTrCRfUwCOMeta } from "/app/pages/auth/investor/onboarding/register.vue?macro=true";
import { default as verifybqoiWDe6ycMeta } from "/app/pages/auth/investor/onboarding/verify.vue?macro=true";
import { default as welcome_45registerjn7gpHefckMeta } from "/app/pages/auth/investor/onboarding/welcome-register.vue?macro=true";
import { default as redirect_45to_45loginjek8xdxhC2Meta } from "/app/pages/auth/investor/redirect-to-login.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as email_45not_45verified_45redirectDF6mEiZzkjMeta } from "/app/pages/auth/partner/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verifiedgxlU4GHVTEMeta } from "/app/pages/auth/partner/email-not-verified.vue?macro=true";
import { default as register3om6BaaWpvMeta } from "/app/pages/auth/partner/onboarding/register.vue?macro=true";
import { default as select_45partnerCtrD0ijCMeMeta } from "/app/pages/auth/partner/onboarding/select-partner.vue?macro=true";
import { default as redirect_45to_45login50NP5v9KQ6Meta } from "/app/pages/auth/partner/redirect-to-login.vue?macro=true";
import { default as redirect_45to_45loginbyHoriVc1PMeta } from "/app/pages/auth/redirect-to-login.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as comparebkoxSP3bIuMeta } from "/app/pages/funds/compare.vue?macro=true";
import { default as indexHESYCWIvjHMeta } from "/app/pages/funds/index.vue?macro=true";
import { default as showT9iAptR6iWMeta } from "/app/pages/funds/show.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as page0XJ8diA5SyMeta } from "/app/pages/page.vue?macro=true";
import { default as sitemapbFBPe0edUzMeta } from "/app/pages/sitemap.vue?macro=true";
import { default as temp_45equityulESlECeAiMeta } from "/app/pages/temp-equity.vue?macro=true";
import { default as showsik2EFeEOuMeta } from "/app/pages/whiteBook/show.vue?macro=true";
import { default as sitemapqjOM9u9BNrMeta } from "~/pages/sitemap.vue?macro=true";
import { default as temp_45equitytcAlUgIx9hMeta } from "~/pages/temp-equity.vue?macro=true";
import { default as maintenancehfTrgCNiVdMeta } from "~/pages/maintenance.vue?macro=true";
import { default as indexvcG9H8G4iyMeta } from "~/pages/articles/index.vue?macro=true";
import { default as showAX2CX1J9UOMeta } from "~/pages/articles/show.vue?macro=true";
import { default as indexOreqd3chIaMeta } from "~/pages/funds/index.vue?macro=true";
import { default as comparevbtyjhU2JdMeta } from "~/pages/funds/compare.vue?macro=true";
import { default as showjfb7Zixg20Meta } from "~/pages/funds/show.vue?macro=true";
import { default as pageWfZ4wX3vgkMeta } from "~/pages/page.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as indext7x5mN60ZyMeta } from "~/pages/account/index.vue?macro=true";
import { default as securityyWf6WBtd8JMeta } from "~/pages/account/security.vue?macro=true";
import { default as teamAk0T6vsBJ8Meta } from "~/pages/account/team.vue?macro=true";
import { default as historyRs1NUeLktbMeta } from "~/pages/account/history.vue?macro=true";
import { default as loginBdfQdmQhmTMeta } from "~/pages/auth/login.vue?macro=true";
import { default as email_45not_45verified_45redirectQJKxxHitCNMeta } from "~/pages/auth/partner/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verified2UA4VJPiu3Meta } from "~/pages/auth/partner/email-not-verified.vue?macro=true";
import { default as email_45not_45verified_45redirectmpKPsuGnMDMeta } from "~/pages/auth/investor/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verified9cX6vbT0ZrMeta } from "~/pages/auth/investor/email-not-verified.vue?macro=true";
import { default as register6OHaFttYHnMeta } from "~/pages/auth/register.vue?macro=true";
import { default as register5SFc1mDW8eMeta } from "~/pages/auth/investor/onboarding/register.vue?macro=true";
import { default as welcome_45registerpoDwGAYGgjMeta } from "~/pages/auth/investor/onboarding/welcome-register.vue?macro=true";
import { default as register9f5AP3weIeMeta } from "~/pages/auth/partner/onboarding/register.vue?macro=true";
import { default as select_45partnerATqsDYTiYoMeta } from "~/pages/auth/partner/onboarding/select-partner.vue?macro=true";
import { default as create_45passwordnRQs8jQqDhMeta } from "~/pages/auth/investor/onboarding/create-password.vue?macro=true";
import { default as verifyd8oyZdKaLoMeta } from "~/pages/auth/investor/onboarding/verify.vue?macro=true";
import { default as showSGQLZmXzeyMeta } from "~/pages/whiteBook/show.vue?macro=true";
export default [
  {
    name: "account-history",
    path: "/account/history",
    component: () => import("/app/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    component: () => import("/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-team",
    path: "/account/team",
    component: () => import("/app/pages/account/team.vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexnFjuerN8cOMeta || {},
    component: () => import("/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-show",
    path: "/articles/show",
    meta: showJgD1hI1UyBMeta || {},
    component: () => import("/app/pages/articles/show.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-email-not-verified-redirect",
    path: "/auth/investor/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectwTbqgD1Vv3Meta || {},
    component: () => import("/app/pages/auth/investor/email-not-verified-redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-email-not-verified",
    path: "/auth/investor/email-not-verified",
    meta: email_45not_45verifiedMmSahp8JOlMeta || {},
    component: () => import("/app/pages/auth/investor/email-not-verified.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-onboarding-create-password",
    path: "/auth/investor/onboarding/create-password",
    meta: create_45passwordqVQ3ZonFVQMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/create-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-onboarding-register",
    path: "/auth/investor/onboarding/register",
    meta: registerhTrCRfUwCOMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-onboarding-verify",
    path: "/auth/investor/onboarding/verify",
    meta: verifybqoiWDe6ycMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/verify.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-onboarding-welcome-register",
    path: "/auth/investor/onboarding/welcome-register",
    meta: welcome_45registerjn7gpHefckMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/welcome-register.vue").then(m => m.default || m)
  },
  {
    name: "auth-investor-redirect-to-login",
    path: "/auth/investor/redirect-to-login",
    meta: redirect_45to_45loginjek8xdxhC2Meta || {},
    component: () => import("/app/pages/auth/investor/redirect-to-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-partner-email-not-verified-redirect",
    path: "/auth/partner/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectDF6mEiZzkjMeta || {},
    component: () => import("/app/pages/auth/partner/email-not-verified-redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-partner-email-not-verified",
    path: "/auth/partner/email-not-verified",
    meta: email_45not_45verifiedgxlU4GHVTEMeta || {},
    component: () => import("/app/pages/auth/partner/email-not-verified.vue").then(m => m.default || m)
  },
  {
    name: "auth-partner-onboarding-register",
    path: "/auth/partner/onboarding/register",
    meta: register3om6BaaWpvMeta || {},
    component: () => import("/app/pages/auth/partner/onboarding/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-partner-onboarding-select-partner",
    path: "/auth/partner/onboarding/select-partner",
    meta: select_45partnerCtrD0ijCMeMeta || {},
    component: () => import("/app/pages/auth/partner/onboarding/select-partner.vue").then(m => m.default || m)
  },
  {
    name: "auth-partner-redirect-to-login",
    path: "/auth/partner/redirect-to-login",
    meta: redirect_45to_45login50NP5v9KQ6Meta || {},
    component: () => import("/app/pages/auth/partner/redirect-to-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect-to-login",
    path: "/auth/redirect-to-login",
    meta: redirect_45to_45loginbyHoriVc1PMeta || {},
    component: () => import("/app/pages/auth/redirect-to-login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "funds-compare",
    path: "/funds/compare",
    meta: comparebkoxSP3bIuMeta || {},
    component: () => import("/app/pages/funds/compare.vue").then(m => m.default || m)
  },
  {
    name: "funds",
    path: "/funds",
    meta: indexHESYCWIvjHMeta || {},
    component: () => import("/app/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: "funds-show",
    path: "/funds/show",
    meta: showT9iAptR6iWMeta || {},
    component: () => import("/app/pages/funds/show.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/page",
    meta: page0XJ8diA5SyMeta || {},
    component: () => import("/app/pages/page.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: sitemapbFBPe0edUzMeta || {},
    component: () => import("/app/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "temp-equity",
    path: "/temp-equity",
    meta: temp_45equityulESlECeAiMeta || {},
    component: () => import("/app/pages/temp-equity.vue").then(m => m.default || m)
  },
  {
    name: "whiteBook-show",
    path: "/whiteBook/show",
    meta: showsik2EFeEOuMeta || {},
    component: () => import("/app/pages/whiteBook/show.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/plan-du-site",
    meta: sitemapqjOM9u9BNrMeta || {},
    component: () => import("~/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "private-equity",
    path: "/private-equity",
    meta: temp_45equitytcAlUgIx9hMeta || {},
    component: () => import("~/pages/temp-equity.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancehfTrgCNiVdMeta || {},
    component: () => import("~/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexvcG9H8G4iyMeta || {},
    component: () => import("~/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "article",
    path: "/articles/:id",
    meta: showAX2CX1J9UOMeta || {},
    component: () => import("~/pages/articles/show.vue").then(m => m.default || m)
  },
  {
    name: "fonds",
    path: "/fonds",
    meta: indexOreqd3chIaMeta || {},
    component: () => import("~/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: "funds",
    path: "/funds",
    component: () => import("~/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: "fundsCompare",
    path: "/fonds/comparer",
    meta: comparevbtyjhU2JdMeta || {},
    component: () => import("~/pages/funds/compare.vue").then(m => m.default || m)
  },
  {
    name: "fond",
    path: "/fonds/:id",
    meta: showjfb7Zixg20Meta || {},
    component: () => import("~/pages/funds/show.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/funds/:id",
    component: () => import("~/pages/funds/show.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:page",
    meta: pageWfZ4wX3vgkMeta || {},
    component: () => import("~/pages/page.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexp9V2p5fxycMeta || {},
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "accountProfil",
    path: "/mon-compte",
    component: () => import("~/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "accountSecurity",
    path: "/mon-compte/securite",
    component: () => import("~/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "accountTeam",
    path: "/mon-compte/equipe",
    component: () => import("~/pages/account/team.vue").then(m => m.default || m)
  },
  {
    name: "accountHistory",
    path: "/mon-compte/historique",
    component: () => import("~/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: "frLogin",
    path: "/connexion",
    meta: loginBdfQdmQhmTMeta || {},
    component: () => import("~/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "partnerEmailNotVerifiedRedirect",
    path: "/partner/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectQJKxxHitCNMeta || {},
    component: () => import("~/pages/auth/partner/email-not-verified-redirect.vue").then(m => m.default || m)
  },
  {
    name: "partnerEmailNotVerified",
    path: "/partner/email-not-verified",
    meta: email_45not_45verified2UA4VJPiu3Meta || {},
    component: () => import("~/pages/auth/partner/email-not-verified.vue").then(m => m.default || m)
  },
  {
    name: "investorEmailNotVerifiedRedirect",
    path: "/investor/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectmpKPsuGnMDMeta || {},
    component: () => import("~/pages/auth/investor/email-not-verified-redirect.vue").then(m => m.default || m)
  },
  {
    name: "investorEmailNotVerified",
    path: "/investor/email-not-verified",
    meta: email_45not_45verified9cX6vbT0ZrMeta || {},
    component: () => import("~/pages/auth/investor/email-not-verified.vue").then(m => m.default || m)
  },
  {
    name: "frRegister",
    path: "/inscription",
    meta: register6OHaFttYHnMeta || {},
    component: () => import("~/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "frInvestorOnboardingRegister",
    path: "/investisseur/inscription",
    meta: register5SFc1mDW8eMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/register.vue").then(m => m.default || m)
  },
  {
    name: "frInvestorOnboardingWelcomeRegister",
    path: "/bienvenue",
    meta: welcome_45registerpoDwGAYGgjMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/welcome-register.vue").then(m => m.default || m)
  },
  {
    name: "frPartnerOnboardingRegister",
    path: "/partenaire/inscription",
    meta: register9f5AP3weIeMeta || {},
    component: () => import("~/pages/auth/partner/onboarding/register.vue").then(m => m.default || m)
  },
  {
    name: "frPartnerOnboardingSelectPartner",
    path: "/partenaire/choisir-son-equipe",
    meta: select_45partnerATqsDYTiYoMeta || {},
    component: () => import("~/pages/auth/partner/onboarding/select-partner.vue").then(m => m.default || m)
  },
  {
    name: "frInvestorOnboardingCreatePassword",
    path: "/investisseur/creer-son-mot-de-passe",
    meta: create_45passwordnRQs8jQqDhMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/create-password.vue").then(m => m.default || m)
  },
  {
    name: "frInvestorOnboardingVerify",
    path: "/investisseur/verification",
    meta: verifyd8oyZdKaLoMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/verify.vue").then(m => m.default || m)
  },
  {
    name: "frWhiteBook",
    path: "/livre-blanc/:slug",
    meta: showSGQLZmXzeyMeta || {},
    component: () => import("~/pages/whiteBook/show.vue").then(m => m.default || m)
  }
]